.main-title {
    font-size: 4em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); // Example drop shadow
    // Other styles...
}


@import url('gists.css');

@font-face {
    font-family: 'Agave';
    src: url('../fonts/AgaveNerdFont-Regular.ttf') format('truetype');
}

body {
    overflow-x: hidden;
    background-color: #202026;
    color: #ccccff;
    font-family: 'Agave', Arial, sans-serif;
    margin: 0;
    display: flex;
}

h1, h2, h3, h4, h5, h6 {
    color: #aaaaff;
}

a, /* Styles for links within the content */
a:visited { /* Styles for visited links */
    color: #9185e3; /* Desired link color */
    text-decoration: none; /* Removes underline, optional */
}

a:hover,
a:focus,
a:active { /* Styles for links on hover, focus, and active states */
    color: #6e90df; /* Maintains the same color on hover, focus, and active states */
    text-decoration: underline; /* Underline on hover, focus, and active, optional */
}

.sidebar {
    background-color: #141418;
    width: 200px;
    height: 100vh;
    position: fixed;
    overflow: auto;
}

.sidebar-list {
    list-style-type: none;
    padding: 0;
}

body, p { // General text elements
    font-size: 1.2em; // Increase the general font size by 1.5x
}

li { // List elements
    font-size: 1.2em;
}

li li { // Nested list elements
    font-size: 1em; // Reset the font size to the default
}

summary {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1em;
}

.sidebar-list li a {
    display: block;
    color: #aaaaff;
    padding: 10px;
    text-decoration: none;
}

.sidebar-list li a:hover {
    background-color: #575757;
    color: white;
}

.container {
    margin-left: 200px;
    padding: 1px 16px;
    text-align: center;
}

.content {
    text-align: left;
    margin-top: 1em;
}

/* Styles for the Floating Action Button */
.fab {
    z-index: 1000; /* Ensures the FAB is above other content */
    display: none; /* Hidden by default */
    position: fixed; /* Fixed position relative to the viewport */
    bottom: 3vw; /* Distance from the bottom of the viewport */
    right: 3vw; /* Distance from the right of the viewport */
    width: 10vw; /* Adjust based on desired size */
    height: 10vw; /* Adjust based on desired size */
    background-color: #141418; /* Sidebar's color */
    color: white;
    border-radius: 50%;
    padding: 15px;
    text-align: center;
    font-size: 2em;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; /* Smooth transition for all properties */
    overflow: hidden; /* Ensures content doesn't overflow during transition */
    cursor: pointer;
}

.fab .fab-close {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent; /* No background color */
}

.fab.expanded {
    border-radius: 15px;
    width: 80vw;
    height: auto;
    // padding: 20px;
    bottom: 80px;
    // left: 10vw;
    right: 7vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.fab.expanded a {
    margin-bottom: 10px;
    color: white;
    text-decoration: none;
}

@media (max-width: 1024px) {
    .fab {
        display: flex;
    }
    .sidebar {
        display: none;
    }
    .container {
        margin-left: 0;
    }
    .mobile-menu {
        display: none;
    }
}

$background: #15151f;

.highlight {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  background-color: $background;
  white-space: pre-wrap; /* Updated property */

  .hll {
    background-color: #49483e;
  }
  .c {
    color: #a8a38d;
  }
  .err {
    color: #960050;
    background-color: #1e0010;
  }
  .k {
    color: #66d9ef;
  }
  .l {
    color: #ae81ff;
  }
  .n {
    color: #f8f8f2;
  }
  .o {
    color: #f92672;
  }
  .p {
    color: #f8f8f2;
  }
  .cm, .cp, .c1, .cs {
    color: #a8a38d;
  }
  .ge {
    font-style: italic;
  }
  .gs {
    font-weight: bold;
  }
  .kc, .kd {
    color: #66d9ef;
  }
  .kn {
    color: #f92672;
  }
  .kp, .kr, .kt {
    color: #66d9ef;
  }
  .ld {
    color: #e6db74;
  }
  .m {
    color: #ae81ff;
  }
  .s {
    color: #e6db74;
  }
  .na {
    color: #a6e22e;
  }
  .nb {
    color: #f8f8f2;
  }
  .nc {
    color: #a6e22e;
  }
  .no {
    color: #66d9ef;
  }
  .nd {
    color: #a6e22e;
  }
  .ni {
    color: #f8f8f2;
  }
  .ne, .nf {
    color: #a6e22e;
  }
  .nl, .nn {
    color: #f8f8f2;
  }
  .nx {
    color: #a6e22e;
  }
  .py {
    color: #f8f8f2;
  }
  .nt {
    color: #f92672;
  }
  .nv {
    color: #f8f8f2;
  }
  .ow {
    color: #f92672;
  }
  .w {
    color: #f8f8f2;
  }
  .mf, .mh, .mi, .mo {
    color: #ae81ff;
  }
  .sb, .sc, .sd, .s2 {
    color: #e6db74;
  }
  .se {
    color: #ae81ff;
  }
  .sh, .si, .sx, .sr, .s1, .ss {
    color: #e6db74;
  }
  .bp, .vc, .vg, .vi {
    color: #f8f8f2;
  }
  .il {
    color: #ae81ff;
  }
  .gh {}
  .gu {
    color: #75715e;
  }
  .gd {
    color: #f92672;
  }
  .gi {
    color: #a6e22e;
  }
}

code{
  background-color: "#282C34";
  color: whitesmoke;
  white-space: pre-wrap; /* Updated property */
}
pre {
  background-color: "#282C34";
  color: whitesmoke;
  white-space: pre-wrap; /* Updated property */
}

.background-overlay {
  display: none;    /* Initially hidden */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  z-index: 90;      /* Below the image (ensure your image z-index is higher) */
}

/* In your Jekyll CSS file */
.fade-image {
  position: fixed; /* Position the image over the rest of the content */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  max-height: 85vh !important; /* Set maximum height to 75% of viewport height */
  max-width: auto; /* Maintain aspect ratio on scaling */
  opacity: 0;   /* Initially hidden */
  z-index: 100; /* Make sure the image appears on top */ 
}

.fade-image.active {
  opacity: 1; /* Make it visible */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5); /* Add a shadow */
}
